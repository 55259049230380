<template>
  <!-- Templates table -->
  <section class="w-full h-full">
    <PageHeader
      :heading="$t('xpbx.pages.heading.templates')"
      :showButton="false"
    />

    <!-- Templates table -->
    <div class="card relative table-wrapper">
      <!-- Table -->
      <DataTable
        ref="dt"
        selectionMode="single"
        dataKey="id"
        :paginator="true"
        :rows="10"
        scrollable
        :scrollHeight="`${scrollHeight}px`"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        :value="templates"
        filterDisplay="menu"
        :rowClass="rowClass"
        paginatorPosition="top"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} templates"
        :rowsPerPageOptions="[5, 10, 25]"
        v-if="templates && templates.length"
        @rowSelect="onRowSelect"
        @rowUnselect="onRowUnselect"
        :globalFilterFields="['description']"
      >
        <template #header>
          <div class="flex gap-2 align-items-center justify-between">
            <div>
              <Button
                label="New"
                icon="pi pi-plus"
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
              />
              <Button
                label="Delete"
                icon="pi pi-trash"
                severity="danger"
                class="delete-button"
                @click="confirmDeleteSelected"
                :disabled="!selectedRecord?.id"
              />
            </div>
            <div class="flex gap-2 items-center">
              <IconField iconPosition="left">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Search..."
                />
              </IconField>
              <Export
                :dt="dt"
                :tableData="templates"
                :columns="temlateHeaders"
              />
            </div>
          </div>
        </template>
        <Column
          sortable
          field="description"
          :header="$t('xpbx.table-field.name')"
        >
          <template #body="{ data }">
            {{ data?.description }}
          </template>
        </Column>
        <Column
          sortable
          field="message"
          :header="$t('xpbx.table-field.content')"
        >
          <template #body="{ data }">
            {{ data?.message }}
          </template>
        </Column>
        <Column
          sortable
          field="channel"
          :header="$t('xpbx.table-field.channel')"
        >
          <template #body="{ data }">
            {{ data?.channel }}
          </template>
        </Column>
      </DataTable>
      <Loader v-else />
    </div>

    <!-- Dialogs -->
    <Dialog
      v-model:visible="deleteRecordsDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.description">{{
          $t("notify.xpbx.message.confirm_delete", {
            delete: `template ${selectedRecord?.description}`,
          })
        }}</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          text
          @click="deleteRecordsDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          text
          @click="deleteSelectedRecords"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="templateDialog"
      :style="{ width: '450px' }"
      :header="
        isEdit
          ? $t('xpbx.pages.heading.edit_template')
          : $t('xpbx.pages.heading.create_template')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!-- Title -->
      <div class="field mb-1 relative">
        <label for="channel">{{ $t("xpbx.labels.channel") }}</label>
        <form-select
          v-model="template.channel"
          :options="templatePeriods"
          name="channel"
          id="channel"
          :errors="errors.channel"
          :activeClass="true"
        />
      </div>
      <QueueHint :title="$t('xpbx.hints.templates.channel')" />

      <!-- Template name -->
      <div class="field mb-1">
        <label for="description">{{
          $t("xpbx.placeholders.template_name")
        }}</label>
        <InputText
          id="description"
          v-model.trim="template.description"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !template.description }"
        />
        <small class="p-error" v-if="submitted && !!errors?.description?.[0]">{{
          errors?.description?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.templates.description')" />

      <!-- Content -->
      <div class="field mb-1">
        <label for="message">{{ $t("xpbx.placeholders.content") }}</label>
        <Textarea
          id="message"
          v-model="template.message"
          required="true"
          rows="3"
          cols="20"
          :class="{ 'p-invalid': submitted && !template.message }"
        />
        <small class="p-error" v-if="submitted && !!errors?.message?.[0]">{{
          errors?.message?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.templates.message')" />

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          text
          @click="templateDialog = false"
        />
        <Button label="Save" icon="pi pi-check" text @click="create" />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import TableSettings from "@/modules/xpbx/components/UI/table-settings";
import useTemplates from "@/modules/xpbx/composables/useTemplates";
import StatusIcon from "@/modules/xpbx/components/UI/status-icon/index.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Textarea from "primevue/textarea";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { validateTemplate } from "@/composables/auth/templateValidations";
import { temlateHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "TemplatesTable",

  components: {
    PageHeader,
    TableSettings,
    StatusIcon,
    Loader,
    DataTable,
    Column,
    Button,
    InputText,
    Export,
    Dialog,
    IconField,
    InputIcon,
    Textarea,
    QueueHint,
  },

  setup() {
    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors } = validator();
    const templateDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const scrollHeight = ref(window.innerHeight - 290);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      // description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      // message: { value: null, matchMode: FilterMatchMode.CONTAINS },
      // channel: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const {
      findTemplates,
      deleteTemplate,
      createTemplate,
      updateTemplate,
      templates,
      template,
    } = useTemplates();

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        template.value = event?.data;
        isEdit.value = true;
        templateDialog.value = true;
      }
    };

    // Data Table Methods
    const openNew = () => {
      template.value = {
        description: "",
        message: "",
        channel: "SMS",
      };
      isEdit.value = false;
      templateDialog.value = true;
    };

    const reset = () => {
      template.value = {
        description: "",
        message: "",
        channel: "SMS",
      };
      submitted.value = false;
      selectedRecord.value = null;
    };

    const closeDialog = (value) => {
      templateDialog.value = value;
    };

    const create = async () => {
      submitted.value = true;
      const isValid = validateTemplate(template.value);

      if (isValid) {
        if (isEdit.value === true) {
          await updateTemplate(template.value, template.value.id);
        } else {
          await createTemplate(template.value);
        }

        isEdit.value = false;
        templateDialog.value = false;
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteTemplate(selectedRecords.value.id);
      deleteRecordsDialog.value = false;
    };

    const templatePeriods = [
      { value: "SMS", name: "SMS" },
      { value: "VIBER", name: "VIBER" },
    ];

    const rowClass = (data) => {
      return [
        {
          "table-active": data.is_active === 1,
          "table-inactive": data.is_active === 0,
          "table-selected": isEdit.value && template.value?.id === data?.id,
        },
      ];
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(templateDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      checkTableClick();
      await findTemplates();
    });

    return {
      dt,
      filters,
      isEdit,
      errors,
      template,
      templateDialog,
      templates,
      templatePeriods,
      temlateHeaders,
      rowClass,
      submitted,
      scrollHeight,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      create,
      reset,
      openNew,
      closeDialog,
      onRowSelect,
      onRowUnselect,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>


<style lang="scss" scoped>
.p-button.add-record-button {
  background: rgba(#32bcad, 0.8);
  border: 1px solid rgba(#32bcad, 0.8);

  &:hover {
    background: rgba(#32bcad, 1);
    border: 1px solid #32bcad;
  }
}

.p-button.delete-button {
  background: #f05a94;
  border: 1px solid #f05a94;

  &:hover {
    background: #f05a94;
  }
}

.p-button.delete-button.p-disabled {
  background: rgba(#f05a94, 0.5);
  border: 1px solid rgba(#f05a94, 0.5);
  border-color: rgba(#f05a94, 0.5);

  &:hover {
    background: rgba(#f05a94, 0.8);
    border-color: rgba(#f05a94, 0.8);
  }
}
</style>