import { ref, inject } from "vue";
import api from "@/services/userApi";

export default function useTemplates() {
  const t = inject("t");
  const template = ref({
    description: "",
    message: "",
    channel: "SMS",
  });
  const templates = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND TEMPLATES
  const findTemplates = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/template");

      if (response?.data) templates.value = response.data;
    } catch (error) {}
  };

  //   FIND TEMPLATE
  const findTemplate = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/template/${id}`);

      if (response?.data?.length) {
        const templateData = {
          ...response.data[0],
          channel: response.data[0].channel || "SMS",
        };
        template.value = templateData;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   CREATE TEMPLATE
  const createTemplate = async (data) => {
    try {
      const response = await api.post(`/admin/xpbx/settings/template`, data);

      if (response?.data?.id) {
        const oldTemplates = [...templates.value];
        const newTemplate = [...oldTemplates, response.data];
        templates.value = newTemplate;
        n("notify.xpbx.create_template", "success");
      } else {
        n("notify.xpbx.error_create_template");
      }
    } catch (error) {
      n("notify.xpbx.error_create_template");
    }
  };

  //   UPDATE TEMPLATE
  const updateTemplate = async (data, id) => {
    try {
      const response = await api.put(
        `/admin/xpbx/settings/template/${id}`,
        data
      );

      if (response?.data?.id) {
        const oldTemplates = [...templates.value];
        const newTemplates = oldTemplates.map((i) =>
          i.id === id ? response.data : i
        );

        templates.value = newTemplates;
        n("notify.xpbx.edit_template", "success");
      } else {
        n("notify.xpbx.error_edit_template", "success");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_template", "success");
    }
  };

  //   DELETE TEMPLATE
  const deleteTemplate = async (id) => {
    try {
      const response = await api.delete(`/admin/xpbx/settings/template/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_template", "success");
        templates.value = templates.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_template");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_template");
      return error;
    }
  };

  //   DELETE TEMPLATES
  const deleteTemplates = async (ids) => {
    for (const item of ids) {
      try {
        const response = await api.delete(
          `/admin/xpbx/settings/template/${item?.id}`
        );
        if (response.status === 200) {
          templates.value = templates.value.filter((i) => i.id !== item.id);
        }
      } catch (error) {
        n("notify.xpbx.error_delete_template");
      }
    }

    n("notify.xpbx.delete_template", "success");
  };

  return {
    template,
    templates,
    findTemplates,
    findTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    deleteTemplates,
  };
}
