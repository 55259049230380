<template>
  <div class="bg-white w-full p-3">
    <TemplatesTable />
  </div>
</template>

<script>
import TemplatesTable from "@/modules/xpbx/components/settings/templates-table/index.vue";

export default {
  name: "SettingTemplates",
  components: {
    TemplatesTable,
  },
};
</script>