import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const { checkRequired, errors, clearErrors } = validator();

export function validateTemplate(template) {
  clearErrors();

  template = unref(template);

  checkRequired("description", template);
  checkRequired("message", template);
  checkRequired("channel", template);

  return hasErrors.value ? false : true;
}

export function validateTimezone(template) {
  clearErrors();

  template = unref(template);

  checkRequired("description", template);

  return hasErrors.value ? false : true;
}

export function validateQueueGeneral(queue) {
  clearErrors();

  queue = unref(queue);

  checkRequired("name", queue);
  checkRequired("queue_number", queue);
  checkRequired("queue_number", queue);
  checkRequired("strategy", queue);
  checkRequired("timeout", queue);

  return hasErrors.value ? false : true;
}
